<template>
    <div class="form-group" style="padding: 15px 0">
      <avue-form ref="form" v-model="dataForm" :option="option">
        <template
          v-for="item in labelSlotList"
          :slot="`${item.prop}Label`"
          slot-scope="scope"
        >
          <span :key="item.prop" v-if="scope.column.labelslot">
            <span>{{ scope.column.label }}:</span>
            <img
              :src="require('@/assets/images/doubt.svg')"
              @click="showTips(scope.column)"
              style="
                cursor: pointer;
                width: 14px;
                height: 14px;
                vertical-align: middle;
                margin-top: -3px;
                display: inline-block;
              "
            />
          </span>
          <span :key="item.prop" v-else>
            <span>{{ scope.column.label }}:</span>
          </span>
          <div
            v-if="scope.column.labelExtra"
            :key="item.prop + '1'"
            style="
              line-height: 14px;
              margin-right: 15px;
              color: #f56c6c;
              margin-top: -2px;
            "
          >
            来源数据中台
          </div>
        </template>
      </avue-form>
      <!-- <collapsenew title="表格">
            <template slot="content">
             如果是表格
            </template>
          </collapsenew> -->
      <el-drawer :title="tipsTitle" :modal="false" :visible.sync="drawer">
        <ul class="tips-wrapper">
          <li>
            <span class="label">属性名称:</span>
            <p class="content">{{ tipsObj.label }}</p>
          </li>
          <li>
            <span class="label">属性说明:</span>
            <p class="content">{{ tipsObj.columnComment }}</p>
          </li>
          <li>
            <span class="label">填写示例:</span>
            <p class="content">{{ tipsObj.commentExample }}</p>
          </li>
        </ul>
      </el-drawer>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      column: {
        type: Array,
        default: () => [],
      },
      group: {
        type: Array,
        default: () => [],
      },
      value: Object,
      detailData: Object,
      isDetail: Boolean,
    },
    watch: {
      detailData: {
        handler(newValue, oldValue) {
         if(newValue) {
           this.dataForm = newValue;
           setTimeout(() => { 
            this.dataForm = newValue;
           },800)
         }
        },
        deep: true,
        immediate: true,
      },
      // detailData(val){
      //   console.log(val,'2222');
      //   this.dataForm = val;
      // },
      value(val){
        this.dataForm = val;
      },
      dataForm(val) {
        this.$emit("input", val);
      },
      isDetail(val) {
        this.option.detail = val;
      },
    },
    computed: {
      labelSlotList() {
        let _list = [];
        if (this.group && this.group.length > 0) {
          var columns = [];
          this.group.forEach((item) => {
            columns = columns.concat(item.column);
          });
          _list = columns.filter((item) => {
            return item.labelslot === true || item.zuojiLab == true;
          });
        } else {
          _list = this.column.filter((item) => {
            return item.labelslot === true || item.labelExtra == true;
          });
        }
        // console.log(_list,'_list');
        return _list;
      },
    },
    data() {
      return {
        dataForm: {},
        form: null,
        option: {
          labelWidth: 200,
          size: "mini",
          submitBtn: false,
          emptyBtn: false,
          gutter: 10,
          column: [],
          group: [],
          pageType: "add",
          // detail:false,
          detail: this.isDetail,
        },
        tipsTitle: "",
        drawer: false,
        tipsObj: {},
      };
    },
    created() {
      // console.log(this.detailData,'detailDatadetailDatadetailData');
      // if (this.detailData) {
      //   this.dataForm = this.detailData;
      //   console.log(this.dataForm ,'this.dataForm ');
      // }
      if (this.group && this.group.length > 0) {
        this.group.forEach((item) => {
          // if(item.isTable === 'Y') {
          //   console.log(item,'item 表格');
          // }else {
  
          // }
          item.icon = "el-icon-s-order";
          item.column = this.initColumn(item.column);
        });
        this.option.group = this.group;
      } else {
        this.option.column = this.initColumn(this.column);
      }
    },
    methods: {
      initColumn(column) {
        var _this = this;
        if (column && column.length > 0) {
          column.forEach(function (item) {
            if (_this.pageType == "detail") {
              item.readonly = true;
            }
            if (item.type == "textarea") {
              item.span = 16;
              item.minRows = 2;
              item.maxRows = 2;
            } else {
              item.span = item.span ? item.span : 8;
            }
          });
        }
        // console.log(column,'column');
        return column;
      },
      //显示提示
      showTips(column) {
        // console.log(column,'column');
        this.tipsObj = column;
        this.drawer = true;
        // this.tipsTitle = column.label;
        // this.$emit('showTips',column);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .form-group::v-deep {
    .avue-group__header {
      height: auto;
      line-height: normal;
      padding: 0;
      position: relative;
      display: flex;
      &::after {
        content: "";
        width: 100%;
        height: 1px;
        border-bottom: 1px dashed #e2e4e8;
        flex: 1;
      }
    }
    .avue-group__title {
      font-weight: 600;
      font-size: 13px;
      color: #333333;
    }
    .avue-group__icon {
      color: #2984f8;
    }
    .el-collapse-item__header {
      border: none !important;
      // border-bottom: 1px dashed #e2e4e8  !important;
      box-shadow: none !important;
      background: none !important;
      margin-bottom: 10px !important;
    }
    .el-form-item__label {
      // overflow: hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp: 2;
      // -webkit-box-orient: vertical;
    }
    .avue--detail {
      .avue-form__group.avue-form__group--flex {
        align-items: stretch;
        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        .el-form-item--mini.el-form-item {
          height: 100%;
          .el-form-item__label,
          .el-form-item__content {
            height: 100%;
          }
        }
      }
      .el-row {
        border-top: none;
        border-left: none;
      }
      // .avue-group--header.avue-group--arrow{
      //   .avue-form__group.avue-form__group--flex{
      //     border:none;
      //   }
      // }
    }
    .el-form-item__label {
      color: #333333;
    }
  }
  .tips-wrapper {
    padding: 0 15px;
    li {
      list-style: none;
      border-bottom: 1px solid #e2e3e7;
      padding: 15px 0;
      display: flex;
      .label {
        width: 85px;
        color: #9c9c9c;
      }
      .content {
        flex: 1;
        color: #000000;
        margin: 0;
      }
    }
  }
  </style>