<template>
  <basic-container class="content">
    <avue-skeleton
      :loading="waiting"
      avatar
      :rows="8"
      style="padding: 0"
      v-if="xiafaState !== '2'"
    >
      <avue-affix id="avue-view" :offset-top="92">
        <div class="header">
          <avue-title :value="paModelInfoName"></avue-title>
        </div>
      </avue-affix>
    </avue-skeleton>

    <div style="position: relative; overflow: hidden">
      <avue-affix id="avue-view" :offset-top="152" v-if="xiafaState !== '2'">
        <anchor
          :anchorList="anchorMap || []"
          :container="'.avue_scrool'"
          :offsetTop="-15"
          :initTop="40"
        ></anchor>
      </avue-affix>

      <div
        v-for="(item, index) in baseGroup"
        :key="index"
        :id="item.id"
        class="articles"
      >
        <collapsenew
          :title="item.label"
          v-if="item.isTable === 'N' && item.isUeditor === 'N'"
          :id="'part' + (index + 2)"
        >
          <template slot="content">
            <form-group
              :column="item.column"
              ref="gudingzichanForm"
              :isDetail="isDetail"
              :detailData="detailData"
              v-model="gudingzichanForm"
            ></form-group>
          </template>
        </collapsenew>
        <collapsenew :title="item.label" v-if="item.isTable === 'Y'">
          <template slot="content">
            <el-row :gutter="22">
              <el-col
                :span="item.columnNum"
                v-for="(ele, i) in item.allData"
                :key="i"
              >
                <TableDetails
                  :key="i"
                  :allTableData="ele"
                  :isDetail="isDetail"
                />
              </el-col>
            </el-row>
          </template>
        </collapsenew>
        <collapsenew :title="item.label" v-if="item.isUeditor === 'Y'">
          <template slot="content">
            <div v-html="item.ueditor.ueditorText"></div>
          </template>
        </collapsenew>
      </div>
    </div>
    <!-- <wf-button
      :loading="submitLoading"
      :button-list="[]"
      :process="process"
      :comment="comment"
      @examine="handleExamine"
      @user-select="handleUserSelect"
      @print="handlePrint"
      @rollback="handleRollbackTask"
      @terminate="handleTerminateProcess"
      @withdraw="handleWithdrawTask"
    >
      <template slot="otherBtn"> -->
    <div class="btns" :style="isCollapse?'width:calc(100% - 100px)':'width: calc(100% - 252px)'">
      <el-button
        v-if="paModelStatus === '0'"
        :loading="submitLoading"
        size="medium"
        type="primary"
        @click="setOpenStatus('1')"
        >启用</el-button
      >

      <el-button
        v-if="paModelStatus === '1'"
        :loading="submitLoading"
        size="medium"
        @click="setOpenStatus('0')"
        >停用</el-button
      >
    </div>

    <!-- </template>
    </wf-button> -->
  </basic-container>
</template>
  
  <script>
import {
  listTemplatePageData,
  pammodelStatus,
} from "@/api/formTemplate/indexValue.js";
import anchor from "./external/components/anchor.vue";
import Collapsenew from "./external/components/collapse-new";
import FormGroup from "./external/components/form-group2.vue";
import FormTable from "./external/components/table.vue";
// import WfButton from "@/views/plugin/workflow/process/components/button.vue";
// import hearderForm from "../../components/header-form.vue";
// import WfExamineForm from "@/views/plugin/workflow/process/components/examForm.vue";
import { detailInit } from "@/api/formTemplate/index";
import TableDetails from "./tableDetails";
import {mapGetters} from "vuex";
export default {
  name: "newDetails",
  props: {
    tempModuleId: String,
  },
  components: {
    anchor,
    FormGroup,
    Collapsenew,
    // WfButton,
    FormTable,
    // hearderForm,
    // WfExamineForm,
    TableDetails,
  },
  data() {
    return {
      xiafaState: "1",
      initInfoDta: {},
      showPage: false,
      isDetail: true,
      detailData: { bh: "12121212" },
      gudingzichanForm: {},
      paModelStatus: "",
      process: { status: "" },
      waiting: true,
      paModelInfoName: "",
      tableData: {},
      baseForm: {},
      baseGroup: [],
      anchorMap: [
        // { name: "报表信息", id: "part2" },
        // { name: "项目信息", id: "part3" },
        // { name: "项目建设所在地及联系方式", id: "part4" },
        // { name: "其他信息", id: "part5" },
        // { name: "项目投资情况", id: "part6" },
        // { name: "说明", id: "part7" },
        // { name: "模板控制属性", id: "part8" },
      ],
      allData: [],
    };
  },
  created() {
    console.log(this.$route.query);
    if (this.$route.query.moduleId) {
      this.detailInit();
      this.init(this.$route.query.moduleId);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters(['isCollapse'])
  },
  watch: {
    tempModuleId: {
      handler(val) {
        if (val) {
          this.xiafaState = "2";
          this.init(val);
          console.log(val, "tempModuleId");
        }
      },
      immediate: true,
    },
  },
  methods: {
    detailInit() {
      detailInit(this.$route.query.busiObj).then((res) => {
        this.initInfoDta = res.data.data;
      });
    },
    setOpenStatus(val) {
      this.submitLoading = true;
      pammodelStatus({
        modelId: this.$route.query.moduleId,
        modelStatus: val,
      }).then((res) => {
        if (res && res.data && res.data.code == "200") {
          this.$message.success(`模板${val === "1" ? "启用" : "停用"}成功`);
        }
        this.submitLoading = false;
        this.init(this.$route.query.moduleId);
        // this.getDetail(this.$route.query.moduleId);
      });
    },
    getFieldType(value) {
      switch (value) {
        case 1:
          return "input";
        case 2:
          return "select";
        case 3:
          return "textarea";
        case 4:
          return "input";
        case 5:
          return "";
        case 6:
          return "radio";
        case 7:
          return "checkbox";
        case 8:
          return "date";
        case 9:
          return "datetime";
        case 10:
          return "input";
        default:
          return "";
      }
    },
    init(moduleId) {
      this.anchorMap = [];
      let groupList = [];
      listTemplatePageData(moduleId).then((res) => {
        let pageList = res.data.data.pageList;
        console.log(pageList, "&&&&");
        this.paModelInfoName = res.data.data.modelInfo.paModelInfoName;
        this.paModelStatus = res.data.data.modelInfo.paModelStatus;
        if (pageList.length > 0) {
          pageList.forEach((item, index) => {
            let numIndex = index + 2;
            let tagItem = {
              name: item.labelName,
              id: "part" + numIndex,
            };
            this.anchorMap.push(tagItem);
            let group = {
              label: item.labelName,
              arrow: true,
              isTable: item.isTable,
              isUeditor: item.isUeditor,
              ueditor: item.ueditor,
              column: [],
              columnNum: item.columnNum,
            };
            if (item.isTable == "Y") {
              group.allData = JSON.parse(item.oldData);
            }
            if (item.columnList.length > 0) {
              item.columnList.forEach((col, index) => {
                let column = {
                  label: col.columnName,
                  prop: col.columnFeild,
                  type: this.getFieldType(col.fieldType),
                  dicData: col.fieldType === 6 ? col.enumList : [], //下拉选择
                  props:
                    col.fieldType === 6
                      ? {}
                      : {
                          label: "dictValue",
                          value: "value",
                        },
                  rules: [],
                  placeholder: "",
                  append: col.unitType === "1" ? "" : col.unitValue, //单位
                  disabled: col.editState === "N" ? true : false, //是否编辑
                  labelslot: col.commentState === "Y" ? true : false, //说明
                  labelExtra: col.isDatacenter === "Y" ? true : false, //中台
                  span: col.singleRowStatus === "Y" ? 24 : item.columnNum,
                  columnComment: col.columnComment, //字段说明
                  commentExample: col.commentExample, //填写示例
                };
                group.column.push(column);
                if (col.dataDefault === "#curUser#") {
                  this.detailData[col.columnFeild] =
                    this.initInfoDta[col.columnFeild];
                } else if (col.dataDefault === "#sysDate#") {
                  this.detailData[col.columnFeild] =
                    this.initInfoDta[col.columnFeild];
                } else {
                  this.detailData[col.columnFeild] = col.dataDefault;
                }
              });
            }
            groupList.push(group);
          });

          this.baseGroup = groupList;
          this.waiting = false;
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
  .content{
    background: #fff;
    padding: 20px;
  }
.header {
  width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}
.btns {
  position: absolute;
  bottom: 0;
  height: 66px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-table .el-table__cell{
  padding: 12px 0;
}
</style>
  