<template>
    <div>
      <div class="link-header">
          <ul>
            <li
              v-for="(item, i) in anchorList"
              @click="toToc(item,i)"
              :key="i"
              :class="{ activeTop: active == i }"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
  
      </div>
    </div>
  </template>
  
  <script>
  import { on, off } from "./dom";
  export default {
    data() {
      return {
        active: 0,
        scrollContainer: null,
      };
    },
    props: {
      container: {
        default: "",
      },
      /**
       * 目录列表
       */
      anchorList: {
        type: Array,
        default: () => {
          return [];
        },
      },
      /**
       * 检测滚动元素的className
       */
      anchorClass: {
        default: "articles",
      },
      /**
       * 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认start
       */
      scorllType: {
        default: "block",
      },
      /**
       * 位置
       */
      position: {
        default: () => {
          return { top: "60px", right: "0" };
        },
      },
      offsetTop:{
        default:25
      },
      initTop:{
        default:0
      }
    },
    watch: {},
    computed: {},
    mounted() {
      //console.log('开始了')
      this.init();
      // window.addEventListener("scroll", this.onScroll);
    },
    created() {
  
    },
    beforeDestroy() {
      this.removeListener();
    },
  
    methods: {
      getContainer() {
        this.scrollContainer = this.container
          ? typeof this.container === "string"
            ? document.querySelector(this.container)
            : this.container
          : window;
        this.scrollElement = this.container
          ? this.scrollContainer
          : document.documentElement || document.body;
      },
      //   scroll函数
      onScroll(e) {
        const navContents = document.querySelectorAll("." + this.anchorClass);
        //console.log(navContents)
        const offsetTopArr = [];
  
        navContents.forEach((item) => {
          offsetTopArr.push(item.offsetTop);
        });
        //console.log(offsetTopArr)
        //   let aa = document.getElementById("article");
        //   const scrollTop = aa.pageYOffset;
  
        const scrollTop =
          e.target.scrollTop ||
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; // 滚动条偏移量
        if (!scrollTop) {
          return;
        }
        //console.log(scrollTop,e.target.scrollTop,window.pageYOffset,document.documentElement.scrollTop,document.body.scrollTop)
  
        let navIndex = 0;
        for (let n = 0; n < offsetTopArr.length; n++) {
          if (scrollTop + this.initTop >= offsetTopArr[n]) {
            navIndex = n;
          }
        }
        //console.log(offsetTopArr,scrollTop)
  
        this.active = navIndex;
      },
      // 跳转到指定锚点
      toToc(a,i) {
        let as = "#" + a.id;
  // this.active = i;
        // document.querySelector(as).scrollIntoView({
        //   behavior: "smooth", // 平滑过渡
        //   block: "start",
        // });
        // setTimeout(function(){
        //   document.querySelector('.avue_scrool').scrollBy(0,-120)
        // },300)
        if(document.querySelector(as))
        document.querySelector('.avue_scrool').scrollTo({top:document.querySelector(as).offsetTop+this.offsetTop,behavior:'smooth'})
  
      },
      init() {
        this.$nextTick(() => {
  
          this.removeListener();
          this.getContainer();
          on(this.scrollContainer, "scroll", this.onScroll);
        });
      },
      removeListener() {
        off(this.scrollContainer, "scroll", this.onScroll);
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .link-header{
      width: 100%;
      background: #fff;
      padding-bottom: 10px;
      border-bottom: 1px solid #ddd;
      ul{
        margin: 0;
        padding: 0;
        list-style: none;
        overflow: hidden;
        li{
          float:left;
          padding:8px 0 8px 70px;
          position: relative;
          cursor: pointer;
          span{
            padding-left: 10px;
          }
          &.activeTop{
            span{
              color: #0087FB;
            }
            &::before{
              content: '';
              display: block;
              position: absolute;
              width:5px;
              height: 5px;
              background: #0087FB;
              border-radius: 5px;
              top: 16px;
            }
  
          }
          &::after{
            content: '';
            display: block;
            position: absolute;
            left: 15px;
            width: 40px;
            border-bottom: 1px dashed #ccc;
            height: 0;
            top: 18px;
          }
          &:nth-of-type(1){
              padding: 8px 0;
          }
        }
      }
  
  
    }
  </style>
  