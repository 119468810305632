<template>
    <div style="padding: 0 15px;">
      <form-head title="表格表格表格表格表格表格表格表格 " />
      <el-table
      :data="tableData"
      size="mini"
      border
      style="width: 100%">
      <el-table-column
        prop="date"
        label="日期"
        width="180">
      </el-table-column>
      <el-table-column
        prop="name"
        label="姓名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="address"
        label="地址">
      </el-table-column>
    </el-table>
    </div>
  </template>
  
  <script>
  import FormHead from "./dashed-head";
  export default {
    components: { FormHead },
      props: {
      column: {
        type: Array,
        default: () => []
      },
      group: {
        type: Array,
        default: () => []
      },
      value: Object,
      isDetail:Boolean
    },
    name: 'from-table',
    data () {
      return {
          tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
          }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
          }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
          }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
          }]
      }
    },
    created () {
      console.log(222222222);
    },
    mounted () {},
    computed: {},
    watch: {},
    methods: {}
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>
  